export let baseUrl = window.location.origin
if (window.location.hostname === 'localhost' || window.location.hostname === '192.168.4.154') baseUrl = 'http://localhost:9000';
export const cockpit = 'https://cockpit.dornsberg.net'

export const date = new Date();
export let day = date.getDate();
if (day < 10) day = '0' + day;
export let tomorrow = date.getDate() + 1;
if (tomorrow < 10) tomorrow = '0' + tomorrow;
export let month = date.getMonth() + 1;
if (month < 10) month = '0' + month;
export const year = date.getFullYear();

// PAGE TRANSITION //
export const pageTransition = {
	initial: {
		x: '100vw'
	},
	in: {
		x: 0
	},
	out: {
		x: '100vw'
	},
	transition: {
		duration: .25,
		ease: 'linear'
	},
	style: {
		position: "absolute",
		width: '100%'
	}
};

// TRANSLATIONS //

export const lang = navigator.language.substring(0, 2) || navigator.userLanguage.substring(0, 2);
document.documentElement.lang = lang;
export const todayLabel = {
	de: 'heute',
	it: 'oggi',
	default: 'today'
}
export const tomorrowLabel = {
	de: 'morgen',
	it: 'domani',
	default: 'tomorrow'
}
export const weatherLabel = {
	de: 'Wetter',
	it: 'Meteo',
	default: 'Weather'
}
export const breadLabel = {
	de: 'Brot bestellen',
	it: 'Ordinare pane',
	default: 'Order bread'
}
export const busLabel = {
	de: 'Busfahrplan',
	it: 'Orario autobus',
	default: 'Bus timetable'
}
export const authText = {
	de: 'Diese App steht ausschließlich Gästen der Residence Dornsberg Panoramic Apartments zur Verfügung.<br/><br/>\
	Verbinden Sie sich mit dem WLAN "Dornsberg", um diese App freizuschalten. Das WLAN-Passwort finden Sie im Willkommensbrief.',
	it: 'Questa applicazione è disponibile solo per gli ospiti del Residence Dornsberg Panoramic Apartments.<br/><br/>\
	Per attivare l\'app si deve connettere con il Wi-Fi "Dornsberg". La password si trova nella lettera di benvenuto.',
	en: 'This application is dedicated only for guest of the Residence Dornsberg Panoramic Apartments.<br/><br/>\
	To use this app, pleace connect to the Wi-Fi "Dornsberg". The password you can find in the welcome letter.'
}
export const authButton = {
	de: 'Nochmal versuchen',
	it: 'Riprovare',
	en: 'Try again'
}
export const authAgain = {
	de: 'Verbinden Sie sich mit dem WLAN "Dornsberg", um diese App erneut freizuschalten.',
	it: 'Connettere di nuovo col Wi-Fi "Dornsberg" per riattivare questa applicazione.',
	en: 'Connect to the Wi-Fi "Dornsberg" to use this app again.'
}
export const breadText = {
	de: 'Als Ergänzung für Ihr Frühstück versorgen wir Sie gerne mit den gewünschten Brötchen frisch vom Bäcker. Eine Bestellung kann hier bis 18 Uhr des Vortages aufgegeben werden.',
	it: 'Come aggiunta alla vostra prima colazione vi forniremo volentieri i panini che desiderate, freschi dal panettiere. L\'ordine può effettuato qui entro le ore 18 del giorno precedente.',
	en: 'Every morning we offer you fresh bread from our bakery. You can order here the bread until 6 pm at the previous day.'
}


// SHOP
export const shopCTA = {
	de: 'Hier tippen, um im Shop einzukaufen',
	it: 'Tocca qui per fare la spesa nello shop',
	en: 'Buy something in the shop here'
}
export const productNumb = {
	de: 'Produkt wählen',
	it: 'Scegliere prodotto',
	en: 'Choose product'
}
export const priceDisclaimer = {
	de: 'inkl. MwSt.',
	it: 'IVA inclusa',
	en: 'VAT included'
}
export const addToCart = {
	de: 'In den Warenkorb',
	it: 'Aggiungi al carello',
	en: 'Add to cart'
}
export const checkoutLabel = {
	de: 'Zur Kasse',
	it: 'Alla cassa',
	en: 'To the checkout'
}
export const checkOutLabel = {
	de: 'Kasse',
	it: 'Cassa',
	en: 'Checkout'
}
export const cartLabel = {
	de: 'Warenkorb',
	it: 'Carello',
	en: 'Cart'
}
export const deleteLabel = {
	de: 'löschen',
	it: 'cancellare',
	en: 'delete'
}
export const emptyCartLabel = {
	de: 'Der Warenkorb ist leer.',
	it: 'Il carello è vuoto.',
	en: 'Cart is empty.'
}
export const shopOnLabel = {
	de: 'Weiter einkaufen',
	it: 'Continua shopping',
	en: 'Continue shopping'
}
export const keycardExplain = {
	de: 'Halten Sie Ihre Zimmerkarte mindestens 3 Sekunden lang an die Oberseite dieses Handys, um Ihren Einkauf abzuschließen.',
	it: 'Tenga la Sua carta chiave dell\'appartamento per circa 3 secondi al lato superiore di questo dispositivo per confirmare il Suo acquisto.',
	en: 'Hold your room keycard at least 3 seconds to the top of this cellphone to submit your order.'
}
export const keycardError = {
	de: 'Karte ungültig. Bitte benutzen Sie die Zugangskarte zu Ihrem Apartment.',
	it: 'Carta non valida. Usa la carta per il Suo appartamento prego.',
	en: 'Card not valid. Please use your access card to your apartment.'
}
export const checkOutSuccess = {
	de: 'Vielen Dank für Ihren Einkauf! Sie können den Einkauf entnehmen. Wir buchen ihn auf Ihre Zimmerrechnung.',
	it: 'Grazie mille per l\'acquisto! Lei può prendere l\'acquisto.',
	en: 'Thank you for shopping! You can take the product.'
}
export const restart = {
	de: 'Neu starten',
	it: 'Riavviare',
	en: 'Restart'
}
export const stop = {
	de: 'Abbrechen',
	it: 'Annulla',
	en: 'Abort'
}
export const buy = {
	de: 'Jetzt kaufen',
	it: 'Compra adesso',
	en: 'Buy now'
}
export const chooseApt = {
	de: 'Wählen Sie Ihr Apartment',
	it: 'Nome dell\'appartamento',
	en: 'Choose your apartment'
}
export const summaryLabel = {
	de: 'Zusammenfassung',
	it: 'Panoramica',
	en: 'Summary'
}
export const apartment = {
	de: 'Apartment',
	it: 'Appartamento',
	en: 'Apartment'
}
export const product = {
	de: 'Produkt',
	it: 'Prodotto',
	en: 'Product'
}
export const qnt = {
	de: 'Menge',
	it: 'Quantità',
	en: 'Quantity'
}
export const price = {
	de: 'Stückpreis',
	it: 'Prezzo unitario',
	en: 'Unit price'
}
export const total = {
	de: 'Gesamtsumme',
	it: 'Totale',
	en: 'Total amount'
}
// BREAD ORDER
export const breadOrderLabel = {
	de: 'Brötchenservice',
	it: 'Servizio pane',
	en: 'Bread service'
}
export const breadOrderCTA = {
	de: 'Hier tippen, um Brot für morgen zu bestellen',
	it: 'Tocca qui per ordinare del pane per domani',
	en: 'Order bread for tomorrow here'
}
export const breadOrderTooLate = {
	de: 'Brotbestellungen können nur bis 18 Uhr aufgegeben werden! Wir bitten um Verständnis.',
	it: 'Ordini del pane possono essere accettati solo entro le ore 18! Ci scusiamo per il disagio.',
	en: 'Bread orders can only be placed until 6 pm! We apologise for any inconvenience.'
}
export const breadOrderNotPossible = {
	de: 'Brotbestellungen für morgen nicht möglich, da sonntags kein Brot geliefert wird. Bitte bestellen Sie morgen wieder.',
	it: 'Ordini del pane per domani non possibile. Nessuna consegna per la domenica. Riprova domani per favore.',
	en: 'Bread orders for tomorrow are not possible as no bread is delivered on Sundays. Please order again tomorrow.'
}
export const breadListLabel = {
	de: 'Brotauswahl',
	it: 'Selezione del pane',
	en: 'Bread selection'
}
export const addToOrder = {
	de: 'Zur Bestellung hinzufügen',
	it: 'Aggiungi all\'ordine',
	en: 'Add to order'
}
export const orderOnLabel = {
	de: 'Weiter bestellen',
	it: 'Continua ordinare',
	en: 'Continue order'
}
export const orderNow = {
	de: 'Bestellung absenden',
	it: 'Invia l\'ordine',
	en: 'Send order'
}
export const orderSuccess = {
	de: 'Vielen Dank für Ihre Bestellung! Wir hängen das frische Brot morgen gegen 7 Uhr an Ihre Türklinke und buchen diese Bestellung auf Ihre Zimmerrechnung. Den leeren Brotsack können Sie hier zurücklegen. Danke!',
	it: 'Grazie mille per l\'ordine! Mettiamo il pane fresco domani verso le ore 7 alla Vostra maniglia e aggiungiamo l\'ordine al Vostro conto. Il sacchetto vuoto del pane può essere consegnato qui. Grazie!',
	en: 'Thank you for your order! We\'ll hang the fresh bread on your door handle around 7 am tomorrow and put this order on your room bill. You can return the empty bread sack back here. Thanks.'
}
export const readMore = {
	de: 'Weiterlesen',
	it: 'Leggi di più',
	en: 'Read more'
}
// Rental service
export const rentalServiceLabel = {
	de: 'Leihservice',
	it: 'Servizio di noleggio',
	en: 'Rental service'
}
export const rentalServiceCTA = {
	de: 'Hier tippen, um auszuleihen',
	it: 'Tocca qui per noleggiare',
	en: 'Press here to rent'
}
export const dayLabel = {
	de: 'Tag',
	it: 'giorno',
	en: 'day'
}
export const proceed = {
	de: 'Weiter',
	it: 'Procedere',
	en: 'Proceed'
}
export const calLegendAvailable = {
	de: 'Verfügbar',
	it: 'Disponibile',
	en: 'Available'
}
export const calLegendNotAvailable = {
	de: 'Nicht verfügbar',
	it: 'Non disponibile',
	en: 'Not available'
}
export const bookingLabel = {
	de: 'Jetzt buchen',
	it: 'Prenotare adesso',
	en: 'Book now'
}
export const bookMoreDaysLabel = {
	de: 'Tag hinzufügen',
	it: 'Aggiungi giorno',
	en: 'Add day'
}
export const bookMoreLabel = {
	de: 'Anderen Artikel buchen',
	it: 'Prenta altro articolo',
	en: 'Book an other item'
}
export const selectionLabel = {
	de: 'Buchungsdaten',
	it: 'Dati di prenotazione',
	en: 'Booking dates'
}
export const sendRentalLabel = {
	de: 'Leihbedingungen akzeptieren und Buchung absenden',
	it: 'Accetta le condizioni di noleggio ed invia la prenotazione',
	en: 'Accept rental conditions and send booking'
}
export const noRentalItems = {
	de: 'Derzeit stehen keine Leihartikel zur Verfügung.',
	it: 'Al momento non ci sono articoli a noleggio disponibili.',
	en: 'There are currently no rental items available.'
}
export const backToOverviewLabel = {
	de: 'Zurück zur Übersicht',
	it: 'Torna all\'elenco',
	en: 'Back to overview'
}
export const confirmBookingLabel = {
	de: 'Bestätigen und weiter',
	it: 'Conferma e continua',
	en: 'Confirm and continue'
}
export const orderSummary = {
	de: 'Ihre Bestellung',
	it: 'Sua ordine',
	en: 'Your order'
}
export const publicHoliday = {
	de: 'Aufgrund des morgigen Feiertages steht heute nur eine reduzierte Brotauswahl zur Verfügung.',
	it: 'A causa della festività di domani, è disponibile oggi solo una selezione ridotta di pane.',
	en: 'Due to tomorrow\'s holiday, only a limited selection of bread is available today.'
}
export const repeatOrder = {
	de: 'Ich möchte diese Bestellung jeden Tag erhalten',
	it: 'Vorrei ricevere questo ordine ogni giorno',
	en: 'Ich möchte diese Bestellung jeden Tag erhalten'
}
export const repeatOrderDesc = {
	de: 'Sie können Ihre Bestellung morgen wieder ändern, indem Sie eine neue aufgeben.',
	it: 'Potete modificare nuovamente il vostro ordine domani inserendolo nuovamente.',
	en: 'You can change your order again tomorrow by re-entering it.'
}
export const repeatOrderCheckout = {
	de: 'Bestellung wiederholen: Ja',
	it: 'Ripetere l\'ordine: Si',
	en: 'Repeat order: Yes'
}
export const cancelOrder = {
	de: 'Meine aktiven Bestellungen stornieren',
	it: 'Annulla i miei ordini attivi',
	en: 'Cancel my active orders'
}