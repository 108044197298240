import * as Constants from '../constants';
import {FiShoppingCart} from 'react-icons/fi';

const ShoppingCart = (prop) => {

	// for rental service
	let rentalDates = 0
	if(prop.shop === 'rental' && prop.cart.length) {
		for (let index = 0; index < prop.cart.length; index++) {
			const item = prop.cart[index];
			if(item.dates.length) {
				for (let i = 0; i < item.dates.length; i++) {
					rentalDates = rentalDates+1
				}
			}
		}
	}

	if(prop.cart.length && prop.page !== 'checkout' && prop.page !== 'success') {
		return (
			<div className="shopping-cart flex">
				<button className="cart-detail-link flex-center align-center" onClick={() => {
						prop.setTitle(Constants.cartLabel);
						prop.setPage('cart');
					}
				}>
					<FiShoppingCart stroke="white" />
					<div className="cart-article flex-center align-center">
						<div className="cart-length">
							{prop.shop === 'rental' ? rentalDates : prop.cart.length}
						</div>
					</div>
				</button>
			</div>
		)
	}
	else return '';
}

export default ShoppingCart;