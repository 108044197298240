import * as Constants from '../constants';
import { useState, useEffect } from 'react';
import Spinner from '../components/Spinner';
import { useForm } from 'react-hook-form';
import {AiFillMinusSquare} from 'react-icons/ai';
import {AiFillPlusSquare} from 'react-icons/ai';
import {IoMdCheckmarkCircle} from 'react-icons/io';

function GetProducts(prop) {

	useEffect(() => {
		fetchProducts();
	}, []);

	let arr = [];
	let fetchSettings;
	const [products, setProducts] = useState([]);
	const [purchases, setPurchases] = useState([]);
	const [stats, setStats] = useState('bestseller');
	const [count, setCount] = useState(1);
	const [apartment, setApartment] = useState('');
	const { register, handleSubmit } = useForm();
	const [more, setMore] = useState(false);
	const [loader, setLoader] = useState(false)

	if(prop.backend === false) fetchSettings = {active: true};

	const fetchProducts = async () => {
		fetch('https://cockpit.dornsberg.net/api/collections/get/shop?token=account-9805cfff4b0ec3f5f36f57910991b1', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				filter: fetchSettings
			})
		})
		.then(data => data.json())
		// push to array
		.then(data => {
			for (let i = 0; i < data.entries.length; i++) {
				arr.push(data.entries[i]);
			}
		})
		// sort array by #
		.then(() => {
			arr.sort((a, b) => a.number - b.number);
			setProducts(arr);
			console.log(arr);
		})
		.catch(function() {
			console.log('Fetch error');
		});
	};

	if(!products.length) return <Spinner />;
	// else console.log(products);

	const ProductList = () => {
		setCount(1);
		if(prop.backend === false) {
			return (
				<div className='product-list flex-center flex-wrap'>
					{products.map((product, index) => (
						<div key={index} className={'product product-'+(index+1)}>
							<button	className="product-number flex-center align-center t4" onClick={() => {
									prop.setArticle(product.number);
									prop.setTitle(product.number);
									prop.setPage('detail');
									// reset read more
									setMore(false);
								}
							}>
								{product.number}
								{product.sale ? <img alt="Sale" className="product-sale" src="../images/sale-tag.png" /> : ''}
							</button>
						</div>
					))}
				</div>
			)
		}
		else {
			return (
				<div className='product-list backend'>
					{products.map((product, index) => (
						<div key={index} className={product.active === true ? 'product product-'+(index+1) : 'product inactive'}>
							<button	className="t4 tal" onClick={() => {
									prop.setArticle(product.number);
									prop.setTitle(product.number);
									prop.setPage('detail');
								}
							}>
								<span className="numb t4">{product.number}</span> <span className="product-name t4">{product['product_'+prop.lang]}</span>
							</button>
						</div>
					))}
					{(prop.backend === true) ? <button className="button1" onClick={() => prop.setTitle('backend')+prop.setPage('backend')}>Alle Einkäufe</button> : ''}
					{(prop.backend === true) ? <button className="button1" onClick={() => prop.setTitle('stats')+prop.setPage('stats')}>Statistiken</button> : ''}
				</div>
			)
		}
	}

	const ProductDetail = () => {
		if(prop.backend === false) {
			return (
				<div className="product-detail">
					{products.map((product, i) => {
						if(prop.article === product.number) {
							return (
								<div key={i} className={'wrapper tac product-'+(i+1)}>
									<div className="product-name t1">{product['product_'+prop.lang]}</div>
									{product['producer_'+prop.lang].length
										? <div className="product-producer t5">{product['producer_'+prop.lang]}</div>
										: ''
									}
									<img alt={product['product_'+prop.lang]} style={{height:"320px"}} className="product-image" src={[Constants.cockpit]+product.picture.path} />
									{product['desc_'+prop.lang].length
										? <div className={more === true ? 'product-desc' : 'product-desc short'}>{product['desc_'+prop.lang]}</div>
										: ''
									}
									{product['desc_'+prop.lang].length && more === false
										? <div className="read-more" onClick={() => setMore(true)}>{Constants.readMore[prop.lang]}</div>
										: ''
									}
									<div className="price-box">
										{product.sale ? <img alt="Sale" className="product-sale" src="../images/sale-tag.png" /> : ''}
										<div className="product-price t2">
											{product.sale ? <div className="product-old-price small"><s>{product.price} €*</s></div> : ''}
											{product.sale ? <b>{product.sale}</b> : product.price} €*
										</div>
										<div className="product-quantity t5">{product.quantity}</div>
										<div className="price-disclaimer small">*{Constants.priceDisclaimer[prop.lang]}</div>
									</div>
								</div>
							)
						}
					})}
					<Counter />
				</div>
			)
		}
		else {
			const onSubmit = data => {
				fetch('https://cockpit.dornsberg.net/api/collections/save/shop?token=account-9805cfff4b0ec3f5f36f57910991b1', {
					method: 'post',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						data: data
					})
				})
				.then(res=>res.json())
				.then(entry => console.log(entry))
				.then(entry => fetchProducts());
			}
			return (
				<div className="product-detail backend">
					{products.map((product, i) => {
						console.log(product)
						if(prop.article === product.number) {
							return (
								<div key={i} className={'wrapper tac product-'+(i+1)}>
									<div className="product-name t1">{product['product_'+prop.lang]}</div>
									<img alt={product['product_'+prop.lang]} style={{height:"320px"}} className="product-image" src={[Constants.cockpit]+product?.picture?.path} />
									<form onSubmit={handleSubmit(onSubmit)}>
										<label htmlFor="active">Aktiv: </label>
										<input type="text" name="active" defaultValue={product.active} {...register('active')} />
										<label htmlFor="number">Produktnummer: </label>
										<input type="number" name="number" defaultValue={product.number} {...register('number')} />
										<label htmlFor="price">Preis: </label>
										<input type="text" name="price" defaultValue={product.price} {...register('price')} />
										<label htmlFor="sale">Sale: </label>
										<input type="text" name="sale" defaultValue={product.sale} {...register('sale')} />
										<label htmlFor="expiry">Verfallsdatum: </label>
										<input type="text" name="expiry" defaultValue={product.expiry} {...register('expiry')} />
										<label htmlFor="sold">verkauft: </label>
										<input type="number" name="sold" defaultValue={product.sold} {...register('sold')} />
										<label htmlFor="stock">Lagerbestand: </label>
										<input type="number" name="stock" defaultValue={product.stock} {...register('stock')} />

										<input type="hidden" name="_id" value={product._id} {...register('_id')} />
										<input type="submit" value="speichern" className="submit button1" />
									</form>
								</div>
							)
						}
					})}
				</div>
			)
		}
	}

	const increment = () => {
		setCount(count + 1)
	}
	const decrement = () => {
		if(count > 1) setCount(count - 1)
	}
	const addToCart = () => {
		// check if product is already in cart
		let duplicate = 0;
		if(prop.cart.length) {
			prop.cart.map((product, i) => {
				if(prop.article === product.product.number) {
					let copy = JSON.parse(JSON.stringify(prop.cart));
					copy[i].quantity = copy[i].quantity + count
					prop.setCart(copy)
					duplicate++;
				}
				if(duplicate === 0 && i+1 === prop.cart.length) addNewItemToCart();
			})
		}
		else addNewItemToCart();
		prop.setTitle(Constants.cartLabel);
		prop.setPage('cart');
	}
	const addNewItemToCart = () => {
		products.map((product, i) => {
			if(prop.article === product.number) {
				prop.setCart([...prop.cart, {
					'product': product,
					'quantity': count
				}]);
			}
		})
	}
	const Counter = () => {
		return (
			<div className="conversion tac">
				<div className="counter flex-center align-center">
					<button className="minus large flex align-center" onClick={decrement}>
						<AiFillMinusSquare />
					</button>
					<div className="count tac large">{count}</div>
					<button className="plus large flex align-center" onClick={increment}>
						<AiFillPlusSquare />
					</button>
				</div>
				<button className="button1 add-to-cart" onClick={addToCart}>{Constants.addToCart[prop.lang]}</button>
			</div>
		)
	}

	const cartIncrement = (i, qnt) => {
		let copy = JSON.parse(JSON.stringify(prop.cart));
		copy[i].quantity = copy[i].quantity +1
		prop.setCart(copy)
	}
	const cartDecrement = (i, qnt) => {
		if(qnt > 1) {
			let copy = JSON.parse(JSON.stringify(prop.cart));
			copy[i].quantity = copy[i].quantity -1
			prop.setCart(copy)
		}
	}
	const delArticle = (i) => {
		let copy = JSON.parse(JSON.stringify(prop.cart));
		copy.splice(i, 1);
		prop.setCart(copy)
	}
	const cartSum = prop.cart.reduce((sum, item) => {
		if(item.product.sale) return sum + (Number(item.product.sale.replaceAll(',','.')) * item.quantity)
		else return sum + (Number(item.product.price.replaceAll(',','.')) * item.quantity)
	},0)
	let cartTotal = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(cartSum);
	console.log(cartSum)
	const CartDetail = () => {
		if(prop.cart.length) return (
			<div className="cart-detail">
				<div className="cart-product-list">
					{prop.cart.map((cart, i) => (
						<div key={i} className={'cart-product product-'+i+' flex space-between'}>
							<div className="product-left">
								<div className="product-name">
									{cart.product.sale ? <img alt="Sale" className="product-sale" width="32" src="../images/sale-tag.png" /> : ''}
									{cart.product['product_'+prop.lang]}
								</div>
								<img alt={cart.product['product_'+prop.lang]} style={{height:"120px"}} className="product-image" src={[Constants.cockpit]+cart.product.picture.path} />
							</div>
							<div className="product-right flex-column space-between">
								<div className="product-quantity flex align-center">
									<button className="minus large flex align-center" onClick={() => cartDecrement(i, cart.quantity)}>
										<AiFillMinusSquare />
									</button>
									<div className="count tac large">{cart.quantity}</div>
									<button className="plus large flex align-center" onClick={() => cartIncrement(i, cart.quantity)}>
										<AiFillPlusSquare />
									</button>
								</div>
								<div className="product-price tar">{cart.product.sale
									? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((Number(cart.product.sale.replaceAll(',','.')) * cart.quantity))
									: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((Number(cart.product.price.replaceAll(',','.')) * cart.quantity))
								}</div>
								<button className="delete-article tar default" onClick={() => delArticle(i)}>{Constants.deleteLabel[prop.lang]}</button>
							</div>
						</div>
					))}
				</div>
				<div className="cart-detail-bottom">
					<div className="total tar">{Constants.total[prop.lang]+': '+cartTotal}</div>
					<div className="cta flex space-between">
						<button className="shop-on button1" onClick={() => {
							prop.setTitle(Constants.productNumb);
							prop.setArticle(false);
							prop.setPage('list');
						}}>
							{Constants.shopOnLabel[prop.lang]}
						</button>
						<button className="checkout button3 t5" onClick={() => {
						// 	prop.setTitle(Constants.checkOutLabel);
						// 	prop.setPage('checkout');
						// }}>
						// 	{Constants.checkoutLabel[prop.lang]}
							prop.setTitle(Constants.checkOutLabel);
							prop.setPage('mail');
						}}>
							{Constants.checkoutLabel[prop.lang]}
						</button>
					</div>
				</div>
			</div>
		)
		else return (
			<div className="cart-detail">
				{Constants.emptyCartLabel[prop.lang]}
			</div>
		)
	}

	// const submitOrder = async () => {
	// 	let data = '';
	// 	prop.cart.map((item, i) => {
	// 		data += (i+1)+'. Produkt: '+item.product.product_de+'<br/>Menge: '+item.quantity+'<br/><br/>';
	// 	});
	// 	console.log(data);
	// 	fetch('https://shop.dornsberg.net/php/mailer.php', {
	// 		method: 'post',
	// 		headers: { 'Content-Type': 'application/json' },
	// 		body: JSON.stringify({
	// 			order: data
	// 		})
	// 	})
	// }

	const sendMail = (data, copy, productState) => {
		setLoader(true)
		let order = [];
		for (var i = 0; i < prop.cart.length; i++) {
			order[i] = {
				'name': prop.cart[i].product.product_de,
				'quantity': prop.cart[i].quantity,
				'extraId': prop.cart[i].product.extraId,
				sale: prop.cart[i].product.sale,
				price: prop.cart[i].product.price,
			};
		}
		console.log(order);
		let todayDate = new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear();
		fetch('https://cockpit.dornsberg.net/api/collections/save/shopping?token=account-9805cfff4b0ec3f5f36f57910991b1', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				data: {
					date: todayDate,
					apartment: apartment,
					order: order
				}
			})
		})
		// update stock and sold
		.then(update => {
			fetch('https://cockpit.dornsberg.net/api/collections/save/shop?token=account-9805cfff4b0ec3f5f36f57910991b1', {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					data: copy
				})
			})
			.then(res=>res.json())
			.then(entry => console.log(entry));
		})
		// send mail
		.then(() => {
			console.log(productState);
			fetch('https://shop.dornsberg.net/php/mailer.php', {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					order: data,
					product_state: productState
				})
			})
			.then(response => {
				if(!response.ok) {
					throw new Error('no repsonse')
				}
				// setKeycard('success');
				setApartment('success');
				prop.setPage('success');
				setTimeout(function(){
					window.location.reload(true);
				}, 15000);
				setLoader(false)
			})
			.catch(error => {
				console.error('Problem: ', error);
			})
		})
		.catch(function() {
			console.log('Fetch error');
		});
	}

	const CheckOut = () => {
		if(apartment === '') {
			return (
				<div className="checkout tac">
					<div className="check-out large m-b-50">{Constants.chooseApt[prop.lang]}</div>
					<div className="apartments flex-center flex-wrap space-between">
						<button className="apartment medium" onClick={()=> setApartment('Frieda')}>Frieda</button>
						<button className="apartment medium" onClick={()=> setApartment('Paula')}>Paula</button>
						<button className="apartment medium" onClick={()=> setApartment('Martha')}>Martha</button>
						<button className="apartment medium" onClick={()=> setApartment('Franzi')}>Franzi</button>
						<button className="apartment medium" onClick={()=> setApartment('Luise')}>Luise</button>
						<button className="apartment medium" onClick={()=> setApartment('Lotte')}>Lotte</button>
						<button className="apartment medium" onClick={()=> setApartment('Greta')}>Greta</button>
						<button className="apartment medium" onClick={()=> setApartment('Anni')}>Anni</button>
						<button className="apartment medium" onClick={()=> setApartment('Marie')}>Marie</button>
					</div>
				</div>
			)
		}
		else if(apartment === 'success') return (
			<div className="check-out tac">
				<div className="icon"><IoMdCheckmarkCircle fill='darkgreen' /></div>
				<div className="explain large">{Constants.checkOutSuccess[prop.lang]}</div>
				<button className="restart button1" onClick={() => window.location.reload(true)}>{Constants.restart[prop.lang]}</button>
			</div>
		)
		else {
			prop.setTitle(Constants.summaryLabel);
			// create send data
			let data = Constants.apartment.de+': '+apartment+'<br/><br/>';
			prop.cart.map((item, i) => {
				data += item.product.product_de+': '+item.quantity+'x'+(item.product.sale ? ' ACHTUNG! PRODUKT IM SALE ZUM EINZELPREIS VON '+item.product.sale+' €' : '')+'<br/>';
			});
			data += '<br/>'+Constants.total.de+': '+cartTotal;
			console.log(data);
			// create new product list with stock and sold update
			let copy = JSON.parse(JSON.stringify(products));
			prop.cart.map((cart, index) => {
				copy.map((product, i) => {
					if(cart.product.number === product.number) {
						// skip private
						if(apartment !== 'Luise') copy[i].sold = Number(copy[i].sold) + cart.quantity
						copy[i].stock = Number(copy[i].stock) - cart.quantity
						console.log(copy)
					}
				})
			})
			// create product state list sort by best sellers
			let productState = [];
			copy.sort((a, b) => b.sold - a.sold);
			copy.map((product, i) => {
				productState += (i+1)+'. '+product.product_de+'(#'+product.number+') --- LAGER: '+product.stock+' --- VERKAUFT: '+product.sold+' --- VERFALLSDATUM: '+product.expiry+'<br/>';
			});
			console.log(productState);
			return (
				<div className="check-out">
					<div className="summary">
						<div className="summary-apartment">{Constants.apartment[prop.lang]+': '+apartment}</div>
						<br/>
						<div className="summary-list">
							{prop.cart.map((item, i) => (
								<div key={i} className="summary-product">
									{item.quantity}x {item.product['product_'+prop.lang]}
								</div>
							))}
						</div>
						<br/>
						<div className="summary-price">{Constants.total[prop.lang]+': '+cartTotal}</div>
					</div>
					<div className="flex-center space-between">
						<button className="abord button1" onClick={() => prop.setTitle(Constants.cartLabel)+prop.setPage('cart')+setApartment('')}>{Constants.stop[prop.lang]}</button>
						<button className="buy button3 t5" onClick={() => sendMail(data, copy, productState)}>{Constants.buy[prop.lang]}</button>
					</div>
				</div>
			)
		}

	}

	// Backend
	// get all purchases
	const fetchPurchases = async () => {
		fetch('https://cockpit.dornsberg.net/api/collections/get/shopping?token=account-9805cfff4b0ec3f5f36f57910991b1', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(data => data.json())
		.then(data => {
			setPurchases(data.entries);
			console.log(data);
		})
		.catch(function() {
			console.log('Fetch error');
		});
	};

	// show all purchases chronological
	const Purchases = () => (
		purchases.map((item, i) => {
			let timestamp = new Date(item._created*1000);
			const purchaseSum = item.order.reduce((sum, item) => {
				if(item.sale) return sum + (Number(item.sale.replaceAll(',','.')) * item.quantity)
				else return sum + (Number(item.price.replaceAll(',','.')) * item.quantity)
			},0)
			let purchaseTotal = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(purchaseSum);
			return (
				<div key={i} className="purchase">
					<div className="purchase-date">{timestamp.toLocaleDateString('de', {weekday: 'long'})}, {timestamp.toLocaleDateString('de')} um {timestamp.toLocaleTimeString('de')} Uhr</div>
					<div className="purchase-apt">Apartment: <span className="bold medium">{item.apartment}</span></div>
					<div className="purchases">
						{item.order.map((order, index) => (
							<div key={index} className="order">
								{order.quantity}x {order.name}
							</div>
						))}
					</div>
					<div className="purchase-total">Summe: {purchaseTotal}</div>
				</div>
			)
		})
	)

	const TotalRevenue = () => {
		let totalRevenue = 0;
		purchases.map((item, i) => {
			const purchaseSum = item.order.reduce((sum, item) => {
				if(item.sale) return sum + (Number(item.sale.replaceAll(',','.')) * item.quantity)
				else return sum + (Number(item.price.replaceAll(',','.')) * item.quantity)
			},0)
			// total revenue
			totalRevenue += purchaseSum
		})
		return (
			<div className='t4 large'>
				Umsatz total: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalRevenue)}
			</div>
		)
	}

	const Backend = () => {
		if(!purchases.length) {
			fetchPurchases();
			return <Spinner />;
		}
		else {
			// sort array by timestamp
			purchases.sort((a, b) => b._created - a._created)
			return (
				<div className="purchases-list">
					<nav className="purchase-filter">
						<div className="filter-title">Apartmentfilter</div>
						<button className="button1" onClick={fetchPurchases}>Alle</button>
						<button className="button1" onClick={() => setPurchases(purchases.filter(item => item.apartment === 'Frieda'))}>Frieda</button>
						<button className="button1" onClick={() => setPurchases(purchases.filter(item => item.apartment === 'Paula'))}>Paula</button>
						<button className="button1" onClick={() => setPurchases(purchases.filter(item => item.apartment === 'Martha'))}>Martha</button>
						<button className="button1" onClick={() => setPurchases(purchases.filter(item => item.apartment === 'Franzi'))}>Franzi</button>
						<button className="button1" onClick={() => setPurchases(purchases.filter(item => item.apartment === 'Luise'))}>Luise</button>
						<button className="button1" onClick={() => setPurchases(purchases.filter(item => item.apartment === 'Lotte'))}>Lotte</button>
						<button className="button1" onClick={() => setPurchases(purchases.filter(item => item.apartment === 'Greta'))}>Greta</button>
						<button className="button1" onClick={() => setPurchases(purchases.filter(item => item.apartment === 'Anni'))}>Anni</button>
						<button className="button1" onClick={() => setPurchases(purchases.filter(item => item.apartment === 'Marie'))}>Marie</button>
					</nav>
					<TotalRevenue />
					<br/>
					<Purchases />
				</div>
			)
		}
	}

	const Bestseller = () => {
		let bestseller = products.sort((a, b) => b.sold - a.sold)
		return (
			<div className="bestseller">
				<div className="stats-title t1">Bestseller</div>
				{bestseller.map((product, i) => (
					<div key={i} className="stats-item">
						{i+1}. {product.product_de}: {product.sold}
					</div>
				))}
			</div>
		)
	}

	const CashCow = () => {
		let cashCow = products.sort((a, b) => (Number(b.price.replaceAll(',','.')) * b.sold) - (Number(a.price.replaceAll(',','.')) * a.sold))
		return (
			<div className="revenue">
				<div className="stats-title t1">Cash Cow</div>
				{cashCow.map((product, i) => {
					let revenue = (Number(product.price.replaceAll(',','.')) * product.sold)
					return (
						<div key={i} className="stats-item">
							{i+1}. {product.product_de}: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(revenue)}
						</div>
					)
				})}
			</div>
		)
	}

	const Stock = () => {
		let stock = products.sort((a, b) => b.stock - a.stock)
		return (
			<div className="stock">
				<div className="stats-title t1">Lagerbestand</div>
				{stock.map((product, i) => (
					<div key={i} className="stats-item">
						{i+1}. {product.product_de}: {product.stock}
					</div>
				))}
			</div>
		)
	}

	const Expiry = () => {
		// create new product list with timestamp
		let copy = JSON.parse(JSON.stringify(products));
		copy.map((product, i) => {
			let expiryDate = product.expiry.replaceAll('.','-');
			expiryDate = expiryDate.split("-");
			let timestamp = new Date(expiryDate[2], expiryDate[1] - 1, expiryDate[0]);
			timestamp = timestamp.getTime();
			copy[i].expiryTimestamp = timestamp;
		})
		let expiry = copy.sort((a, b) => a.expiryTimestamp - b.expiryTimestamp)
		if(expiry === '') return <Spinner />;
		else return (
			<div className="expiry">
				<div className="stats-title t1">Verfallsdatum</div>
				{expiry.map((product, i) => (
					<div key={i} className="stats-item">
						{i+1}. {product.product_de}: {product.expiry}
					</div>
				))}
			</div>
		)
	}

	const Stats = () => {
		if(products === '') return <Spinner />;
		else return (
			<div className="stats">
				<button className="button1" onClick={() => setStats('bestseller')}>Bestseller</button>
				<button className="button1" onClick={() => setStats('cashcow')}>Cash Cow</button>
				<button className="button1" onClick={() => setStats('stock')}>Lagerbestand</button>
				<button className="button1" onClick={() => setStats('expiry')}>Verfallsdatum</button>
				{stats === 'bestseller' ? <Bestseller /> : ''}
				{stats === 'cashcow' ? <CashCow /> : ''}
				{stats === 'stock' ? <Stock /> : ''}
				{stats === 'expiry' ? <Expiry /> : ''}
			</div>
		)
	}

	if(loader === true) return <Spinner />
	else if(prop.page === 'list') return <ProductList />
	else if(prop.title === Constants.cartLabel) return <CartDetail />
	else if(prop.title === Constants.checkOutLabel || prop.title === Constants.summaryLabel) return <CheckOut />
	else if(prop.title === 'backend') return <Backend />
	else if(prop.title === 'stats') return <Stats />
	else return <ProductDetail />


}

export default GetProducts;