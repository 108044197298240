import * as Constants from '../constants';
import { useState, useEffect } from 'react';
import Spinner from '../components/Spinner';
import DatePicker, { registerLocale } from 'react-datepicker'; // https://reactdatepicker.com/
import "react-datepicker/dist/react-datepicker.css";
import { addDays, parseISO } from 'date-fns';
import de from "date-fns/locale/de";
import it from "date-fns/locale/it";
import {TiDelete} from 'react-icons/ti'
import {IoMdCheckmarkCircle} from 'react-icons/io';
registerLocale('de', de)
registerLocale('it', it)

function RentalService(prop) {

	const [items, setItems] = useState(true);
	const [apartment, setApartment] = useState('');
	const [rental, setRental] = useState([]);
	const [rentalContent, setRentalContent] = useState({});
	const [categories, setCategories] = useState([]);
	const [category, setCategory] = useState([])
	const [loader, setLoader] = useState(false)
	const [readMore, setReadMore] = useState([]);
	const [order, setOrder] = useState();
	let more = [];
	let products = []

	useEffect(() => {
		fetchRental()
	}, []);

	useEffect(() => {
		if(rental.length) {
			let cart = []
			rental.map((rental,i) => {
				if(rental.dates.length) cart.push(rental)
			})
			prop.setCart(cart)
		}
		console.log(rental)
	}, [rental]);

	const fetchRental = async () => {
		// get text content
		await fetch('https://cockpit.dornsberg.net/api/singletons/get/rental?token=account-9805cfff4b0ec3f5f36f57910991b1', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(data => data.json())
		.then(data => {
			let intro = {
				de: data.intro,
				it: data.intro_it,
				en: data.intro_en
			}
			let contract = {
				de: data.contract,
				it: data.contract_it,
				en: data.contract_en
			}
			let success = {
				de: data.success,
				it: data.success_it,
				en: data.success_en
			}
			setRentalContent({intro,contract,success})
		})
		// get rental items
		.then(() => {
			fetch('https://cockpit.dornsberg.net/api/collections/get/rental?token=account-9805cfff4b0ec3f5f36f57910991b1', {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					filter: {active:true}
				})
			})
			.then(data => data.json())
			.then(data => {
				data.entries.map((item,i) => {
					// check if item is available date
					if(new Date() >= new Date(item.valid_from) && new Date() <= new Date(item.valid_to)) {
						products.push({
							product: item,
							dates: []
						})
					}
				})
				setRental(products)
				console.log(products)
				// if no rental items are available
				if(!products.length) {
					setItems(false)
				}
			})
			// get categories
			.then(() => {
				fetch('https://cockpit.dornsberg.net/api/collections/collection/rental?token=account-9805cfff4b0ec3f5f36f57910991b1')
				.then(collection => collection.json())
				.then(collection => {
					console.log(products)
					let filter = collection.fields.filter((field) => field.name === 'category');
					// show only categories with active items
					let arr = []
					for (let index = 0; index < filter[0].options.options.length; index++) {
						const cat = filter[0].options.options[index]
						if(products.find(el => el.product.category === cat.value)) arr.push(cat)
					}
					setCategories(arr)
					setCategory(arr[0].value)
				})
				.catch(function() {
					console.log('Fetch error');
				});
			})
		})
		.catch(function() {
			console.log('Fetch error');
		})
	}

	const Category = () => {
		if(categories.length) return (
			<div className="categories flex-center flex-wrap">
				{categories.map((cat, index) => {
					let catLang;
					if(prop.lang === 'de') catLang = cat.value.split(', ')[0]
					if(prop.lang === 'it') catLang = cat.value.split(', ')[1]
					if(prop.lang === 'en') catLang = cat.value.split(', ')[2]
					return (
						<button key={index}
						className={cat.value === category ? 'category button2' : 'category button1'}
						onClick={() => setCategory(cat.value)}>
							{catLang}
						</button>
					)
				})}
			</div>
		)
		else return <Spinner/>
	}

	const updateMore = (i) => {
		more[i] = true;
		setReadMore(more);
	}

	const RentalService = () => (
		<>
			<div className='content' dangerouslySetInnerHTML={{__html: rentalContent.intro[prop.lang]}}></div>
			<Category />
			<div className="rental-list">
				{rental.map((rental, i) => {
					let product = rental.product
					let name = {
						de: product.name,
						it: product.name_it,
						en: product.name_en
					}
					let description = {
						de: product.desc,
						it: product.desc_it,
						en: product.desc_en
					}
					let details = {
						de: product.details,
						it: product.details_it,
						en: product.details_en
					}
					if(rental.product.category === category) return (
						<div key={i} className={'rental rental-'+(i+1)}>
							<div className="rental-name t4">{name[prop.lang]}</div>
							{product.image
								? <img alt={name[prop.lang]} className="rental-image" src={[Constants.cockpit]+'/'+product.image.path} />
								: ''
							}
							{description[prop.lang]
								? <div className='rental-desc'>{description[prop.lang]}</div>
								: ''
							}
							{details[prop.lang]
								? <div className={readMore[i] === true ? 'rental-details' : 'rental-details short'} dangerouslySetInnerHTML={{__html: details[prop.lang]}}></div>
								: ''
							}
							{details[prop.lang] && readMore[i] !== true
								? <div className="read-more" onClick={() => updateMore(i)}>{Constants.readMore[prop.lang]}</div>
								: ''
							}
							<div className="price-box">
								<div className="rental-price t2">
									{product.price} €/{Constants.dayLabel[prop.lang]}*
								</div>
								<div className="price-disclaimer small">*{Constants.priceDisclaimer[prop.lang]}</div>
							</div>
							{rental.dates.length ? <Selection product={product} index={i} /> : <Datepicker product={product} index={i} />}
						</div>
					)
				})}
			</div>
		</>
	)

	const Selection = (data) => {
		return (
			<div className="selection">
				<div className="t4">{Constants.selectionLabel[prop.lang]}:</div>
				<div className="selection-wrapper flex-wrap flex-center">
					{rental.map((item, i) => {
						if(i === data.index) return (
							<>
								{item.dates.map((date, i) => (
									<div key={i} className="selection-date button3">
										<div className="day t1">
											{new Date(date).toLocaleDateString(prop.lang, {weekday:'short'})}
										</div>
										<div className="day large t4">
											{new Date(date).getDate()}
										</div>
										<div className="month default">
											{new Date(date).toLocaleDateString(prop.lang, {month: 'long'})}
										</div>
										<div className="year small">
											{new Date(date).getFullYear()}
										</div>
										<TiDelete className='large' onClick={() => {
											let copy = JSON.parse(JSON.stringify(rental))
											copy[data.index].dates.splice(i, 1)
											setRental(copy)
										}} />
									</div>
								))}
							</>
						)
					})}
				</div>
				<Datepicker product={data.product} index={data.index} />
			</div>
		)
	}

	// Datepicker
	const Datepicker = (data) => {
		// get available days
		let availableArr = []
		const oneDay = 24 * 60 * 60 * 1000
		const availableDays = Math.round(Math.abs((new Date() - new Date(data.product.valid_to)) / oneDay))
		for (let index = 0; index <= availableDays+1; index++) {
			availableArr.push(addDays(new Date(), index))
		}
		// get unavailable days
		let disableDays = []
		for (let index = 0; index < data.product.dates.length; index++) {
			if(new Date(new Date(data.product.dates[index].value).setHours(0)) >= new Date(new Date().setHours(0, 0, 0, 0))) {
				disableDays.push(parseISO(data.product.dates[index].value))
			}
		}
		if(rental[data.index].dates.length) {
			for (let i = 0; i < rental[data.index].dates.length; i++) {
				disableDays.push(new Date(rental[data.index].dates[i]))
			}
		}
		// add date to selection
		const onChange = (date) => {
			let dateIsoString = new Date(new Date(date).getTime() - (new Date(date).getTimezoneOffset() * 60000)).toISOString().split('T00:00:00.000Z')[0]
			let copy = JSON.parse(JSON.stringify(rental))
			copy[data.index].dates.push(dateIsoString)
			// sort by date
			copy[data.index].dates.sort((a,b) => new Date(a) - new Date(b))
			setRental(copy)
		}
		return (
			<div className="datepicker flex-center">
				<div className="button1 select-date">
					<DatePicker
						locale={prop.lang}
						dateFormat="dd.MM.yyyy"
						calendarStartDay={1}
						selected={false}
						minDate={new Date() >= new Date(data.product.valid_from) ? new Date() : new Date(data.product.valid_from)}
						maxDate={new Date(data.product.valid_to)}
						highlightDates={availableArr}
						onChange={onChange}
						excludeDates={disableDays}
						withPortal
						placeholderText={rental[data.index].dates.length ? Constants.bookMoreDaysLabel[prop.lang] : Constants.bookingLabel[prop.lang]}
						shouldCloseOnSelect={false}
						disabledKeyboardNavigation
						onFocus={e => e.target.blur()}
					>
						<div style={{ color: "#3dcc4a" }}>{Constants.calLegendAvailable[prop.lang]}</div>
						<div style={{ color: "red" }}>{Constants.calLegendNotAvailable[prop.lang]}</div>
					</DatePicker>
				</div>
			</div>
		)
	}

	const cartSum = prop.cart.reduce((sum, item) => {
		return sum + (Number(item.product.price.replaceAll(',','.')) * item.dates.length)
	},0)
	let cartTotal = new Intl.NumberFormat(prop.lang, { style: 'currency', currency: 'EUR' }).format(cartSum);

	const Cart =  () => {
		if(prop.cart.length) return (
			<div className="cart-detail">
				<div className="cart-product-list">
					{rental.map((item, i) => {
						if(item.dates.length) {
							let product = item.product
							let name = {
								de: product.name,
								it: product.name_it,
								en: product.name_en
							}
							return (
								<>
									{item.dates.map((date,index) => (
										<div key={index} className={'cart-product product-'+i+' flex space-between'}>
											<div className="product-left">
												<div className="product-name">{name[prop.lang]}</div>
												<img alt={name[prop.lang]} className="product-image" src={[Constants.cockpit]+product.image.path} />
											</div>
											<div className="product-right flex-column space-between">
												<div className="product-quantity flex align-center">
													{new Date(date).toLocaleDateString(prop.lang, {weekday:'long'})}, {new Date(date).toLocaleDateString(prop.lang)}
												</div>
												<div className="product-price tar">{new Intl.NumberFormat(prop.lang, { style: 'currency', currency: 'EUR' }).format(Number(product.price.replaceAll(',','.')))}</div>
												<button className="delete-article default tar" onClick={() => {
													let copy = JSON.parse(JSON.stringify(rental))
													copy[i].dates.splice(index, 1)
													setRental(copy)
												}}>{Constants.deleteLabel[prop.lang]}</button>
											</div>
										</div>
									))}
								</>
							)
						}
					})}
				</div>
				<div className="cart-detail-bottom">
					<div className="total tar">{Constants.total[prop.lang]+': '+cartTotal}</div>
				</div>
				<div className="cta-wrapper">
					<div className="cta flex space-between">
						<button className="shop-on button1" onClick={() => {
							prop.setTitle(Constants.productNumb);
							prop.setPage('list');
						}}>
							{Constants.orderOnLabel[prop.lang]}
						</button>
						<button className="checkout button3 t5" onClick={() => {
							prop.setTitle(Constants.checkOutLabel);
							prop.setPage('mail');
						}}>
							{Constants.checkoutLabel[prop.lang]}
						</button>
					</div>
				</div>
			</div>
		)
		else return (
			<div className="cart-detail">
				{Constants.emptyCartLabel[prop.lang]}
			</div>
		)
	}


	const Checkout = (data) => {
		window.scrollTo(0, 0)
		if(!apartment.length) {
			return (
				<div className="checkout tac">
					<div className="check-out large m-b-50">{Constants.chooseApt[prop.lang]}</div>
					<div className="apartments flex-center flex-wrap space-between">
						<button className="apartment medium" onClick={()=> setApartment('Frieda')}>Frieda</button>
						<button className="apartment medium" onClick={()=> setApartment('Paula')}>Paula</button>
						<button className="apartment medium" onClick={()=> setApartment('Martha')}>Martha</button>
						<button className="apartment medium" onClick={()=> setApartment('Franzi')}>Franzi</button>
						<button className="apartment medium" onClick={()=> setApartment('Luise')}>Luise</button>
						<button className="apartment medium" onClick={()=> setApartment('Lotte')}>Lotte</button>
						<button className="apartment medium" onClick={()=> setApartment('Greta')}>Greta</button>
						<button className="apartment medium" onClick={()=> setApartment('Anni')}>Anni</button>
						<button className="apartment medium" onClick={()=> setApartment('Marie')}>Marie</button>
					</div>
				</div>
			)
		}
		else if(apartment === 'success') {
			window.scrollTo(0, 0);
			return (
				<div className="check-out tac">
					<div className="icon"><IoMdCheckmarkCircle fill='darkgreen' /></div>
					<div className="explain large">
					{rentalContent
						? <div dangerouslySetInnerHTML={{__html: rentalContent.success[prop.lang]}}></div>
						: <Spinner />
					}
					</div>
					<br/>
					<div className="summary t4">{Constants.orderSummary[prop.lang]}:</div>
					<div dangerouslySetInnerHTML={{__html: order}}></div>
					<button className="restart button1" onClick={() => window.location.reload(true)}>{Constants.restart[prop.lang]}</button>
				</div>
			)
		}
		else {
			prop.setTitle(Constants.summaryLabel)
			let cartSum = 0;
			let order = Constants.apartment[prop.lang]+': '+apartment+'<br/>'
			rental.map((rental, i) => {
				if(rental.dates.length) {
					let name = {
						de: rental.product.name,
						it: rental.product.name_it,
						en: rental.product.name_en
					}
					// calc sum euro
					rental.dates.map((date, i) => {
						cartSum = cartSum+Number(rental.product.price)
					})
					order += '<br/>'+name[prop.lang]+'<br/>'
					rental.dates.map((date, i) => {
						order += new Date(date).toLocaleDateString(prop.lang, {weekday: 'long'})+', '+new Date(date).toLocaleDateString(prop.lang)+'<br/>'
					})
				}
			})
			let cartTotal = new Intl.NumberFormat(prop.lang, { style: 'currency', currency: 'EUR' }).format(cartSum);
			order += '<br/>'+Constants.total[prop.lang]+': '+cartTotal;
			return (
				<div className="check-out">
					<div className="summary" dangerouslySetInnerHTML={{__html: order}}></div>
					<br/>
				 	<div className="contract">
				 		{rentalContent
				 			? <div dangerouslySetInnerHTML={{__html: rentalContent.contract[prop.lang]}}></div>
				 			: <Spinner />
				 		}
				 	</div>
					<div className="flex-center space-between">
						<button className="abord button1" onClick={() => prop.setTitle(Constants.cartLabel)+prop.setPage('cart')+setApartment('')}>{Constants.stop[prop.lang]}</button>
						<button className="buy button3 t5" onClick={() => sendMail(order)}>{Constants.sendRentalLabel[prop.lang]}</button>
					</div>
				</div>
			)
		}
	}

	const sendMail = (order) => {
		setLoader(true)
		let data = []
		rental.map((rental, i) => {
			if(rental.dates.length) {
				let dates = []
				if(rental.product.dates.length) dates = rental.product.dates
				rental.dates.map((date, i) => {
					dates.push({value: date})
				})
				let item = {
					_id: rental.product._id,
					dates: dates
				}
				data.push(item)
			}
		})
		// update booking dates on db
		fetch('https://cockpit.dornsberg.net/api/collections/save/rental?token=account-9805cfff4b0ec3f5f36f57910991b1', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				data: data
			})
		})
		.then(() => {
			let form = []
			for (var i = 0; i < prop.cart.length; i++) {
				let dates = []
				for (let index = 0; index < prop.cart[i].dates.length; index++) {
					dates.push(prop.cart[i].dates[index])
				}
				form[i] = {'name': prop.cart[i].product.name, 'dates': dates};
			}
			fetch('https://cockpit.dornsberg.net/api/forms/submit/rental?token=account-9805cfff4b0ec3f5f36f57910991b1', {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					form: {
						apartment: apartment,
						order: form
					}
				})
			})
			.then(() => (
				// send mail
				fetch(Constants.baseUrl+'/php/order.php', {
					method: 'post',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						subject: 'Leihbuchung',
						content: order
					})
				})
			))
			.then(() => {
				setApartment('success');
				setOrder(order);
				prop.setPage('success');
				setLoader(false)
			})
		})
		.catch(error => {
			console.error(error);
		})
	}

	if(loader === true) return <Spinner />
	else if (items === false) return <div className='rental-service'>{Constants.noRentalItems[prop.lang]}</div>
	else if(prop.page === 'list') return (
		<div className='rental-service'>
			{rental.length && rentalContent
				? <RentalService />
				: <Spinner />
			}
		</div>
	)
	else if (prop.title === Constants.cartLabel) return <Cart />
	else if (prop.title === Constants.checkOutLabel || prop.title === Constants.summaryLabel) return <Checkout />
	else return <Spinner />

}

export default RentalService;