import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from "react-cookie";
import './App.css';
import * as Constants from './constants';
import Spinner from './components/Spinner';
import ProductList from './components/ProductList';
import BreadOrder from './components/BreadOrder';
import RentalService from './components/RentalService';
import Language from './components/Language';
import ShoppingCart from './components/ShoppingCart';
import NavHeader from './components/NavHeader';
import { CgMenuGridR } from 'react-icons/cg';
import { GiSlicedBread } from 'react-icons/gi';
import { FiShoppingCart } from 'react-icons/fi';
import { ImExit } from 'react-icons/im';
import { MdDirectionsBike } from 'react-icons/md';


function App() {
	const [language, setLanguage] = useState(navigator.language.substring(0, 2));
	const [cart, setCart] = useState([]);
	const [article, setArticle] = useState(false);
	const [title, setTitle] = useState('');
	const [page, setPage] = useState('intro');
	const [backend, setBackend] = useState(false);
	const timer = useRef(0);
	const [shop, setShop] = useState('');
	const [breadOrderException, setBreadOrderException] = useState();
	useEffect(() => {
		console.log(language);
		console.log(cart);
		console.log(title);
		console.log(article);
		console.log(page);
		sessionStorage.setItem('cart', JSON.stringify(cart));
	},);
	// Authentication
	// check ip
	const [auth, setAuth] = useState('');
	useEffect(() => {
		fetchIP();
	}, []);
	const fetchIP = async () => {
		let token, url
		url = 'https://shop.dornsberg.net/php/ip.php'
		if (window.location.search.includes('?token')) {
			token = window.location.search.split('?')[1]
			window.history.pushState('', '', '/')
			url = 'https://shop.dornsberg.net/php/ip.php?' + token
		}
		await fetch(url)
			.then(data => data.json())
			.then(data => {
				setAuth(data);
			})
			.catch(function () {
				console.log('Fetch error');
			})
			;
	};
	useEffect(() => {
		fetchBread();
	}, [auth]);
	const fetchBread = async () => {
		fetch('https://cockpit.dornsberg.net/api/collections/get/breadOrderDates?token=account-9805cfff4b0ec3f5f36f57910991b1')
			.then(data => data.json())
			// check if bread order has exception
			.then(data => {
				for (let index = 0; index < data.entries.length; index++) {
					const el = data.entries[index];
					if (new Date(el.date).setHours(0) === new Date().setHours(0, 0, 0, 0)) {
						setBreadOrderException({
							active: el.active,
							date: el.date,
							time: el.time,
							text: {
								de: el.text,
								it: el.text_it,
								en: el.text_en,
							}
						})
					}
				}
			})
	}
	// > 3 sec mousedown to enter backend
	let interval = '';
	const handleMouseDownShop = event => {
		if (event.type === "mousedown" || event.type === "touchstart") {
			interval = setInterval(() => {
				timer.current = timer.current + 1;
				console.log(timer);
				if (timer.current === 3) {
					clearInterval(interval);
					setBackend(true);
					setPage('list');
					setTitle(Constants.productNumb)
					setShop('shop')
				}
			}, 1000)
		}
	}
	const handleMouseDown = event => {
		if (event.type === "mousedown" || event.type === "touchstart") {
			interval = setInterval(() => {
				timer.current = timer.current + 1;
				console.log(timer);
				if (timer.current === 3) {
					clearInterval(interval);
					setBackend(true);
					setPage('bread-list');
					setTitle(Constants.productNumb)
					setShop('bakery')
				}
			}, 1000)
		}
	}
	const handleMouseUp = event => {
		if (event.type === "mouseup" || event.type === "touchend") {
			clearInterval(interval);
			timer.current = 0;
		}
	}
	// current timestamp
	const currTimestamp = new Date().getTime();
	// 1 week in milliseconds
	const week = 604800000;
	// access cookies
	const [cookies, setCookies] = useCookies(['accessShop', 'popup']);
	// if access token not exists or is older than a week
	if (!cookies.accessShop || cookies.accessShop < currTimestamp - week) {
		// wait for auth response
		if (!auth) return <div><Spinner /></div>;
		else {
			if (auth === 'access granted') {
				// update existing access token
				if (cookies.accessShop) setCookies('accessShop', currTimestamp, { path: '/', maxAge: '604800' });
				// or set new access token
				else setCookies('accessShop', currTimestamp, { path: '/', maxAge: '604800' });
			}
			else if (auth === 'access denied' && cookies.accessShop < currTimestamp - week) return (
				<div className="intro tac flex-center flex-column align-center">
					<img alt="Dornsberg Panoramic Apartments" className="logo" width="180" src="./images/logo-negative.svg" />
					<p dangerouslySetInnerHTML={{ __html: Constants.authAgain[language] }}></p>
					<button className="button1" onClick={() => window.location.reload()}>
						{Constants.authButton[language]}
					</button>
				</div>
			)
			else if (auth === 'access denied' && !cookies.accessShop) return (
				<div className="intro tac flex-center flex-column align-center">
					<img alt="Dornsberg Panoramic Apartments" className="logo" width="180" src="./images/logo-negative.svg" />
					<p dangerouslySetInnerHTML={{ __html: Constants.authText[language] }}></p>
					<button className="button1" onClick={() => window.location.reload()}>
						{Constants.authButton[language]}
					</button>
				</div>
			)
		}
	}

	const Bakery = () => {
		let bakery
		let msg
		let time
		// if order exception exists
		if (breadOrderException) {
			if (breadOrderException.text) msg = breadOrderException.text[language]
			if (breadOrderException.time) time = breadOrderException.time.split(':')[0]
			if (breadOrderException.active === true) {
				// check if time is expired
				if (breadOrderException.time) {
					if (new Date().getHours() < time) bakery = true
					else {
						bakery = false
						// msg = Constants.breadOrderTooLate[language]
					}
				}
			}
			else bakery = false
		}
		// normal: order before 6 pm & not on Saturday
		else {
			if (new Date().getHours() < 18 && new Date().getDay() !== 6) {
				bakery = true
				msg = Constants.breadOrderCTA[language]
			}
			// no order on Saturday
			else if (new Date().getDay() === 6) {
				bakery = false
				msg = Constants.breadOrderNotPossible[language]
			}
			// order time expired
			else {
				bakery = false
				msg = Constants.breadOrderTooLate[language]
			}
		}
		const handleOnClick = () => {
			if (bakery === true) {
				setShop('bakery')
				setPage('bread-list')
				setTitle(Constants.productNumb)
			}
		}
		const handleStyle = () => {
			if (bakery === false) return { color: 'firebrick' }
		}
		return (
			<div className="cta-intro tac flex-center align-center" onClick={() => handleOnClick()}>
				{bakery ? <><GiSlicedBread className="cta-icon" /><br /></> : ''}
				<div className="cta-text t4 large" style={handleStyle()}>
					{msg}
				</div>
			</div>
		)
	}

	if (cookies.accessShop > currTimestamp - week) {
		return (
			<div className="App">
				{page !== 'intro' || cart.length ?
					<header>
						<div className="head flex align-center space-between">
							<NavHeader lang={language} title={title} setTitle={setTitle} article={article} setArticle={setArticle} page={page} setPage={setPage} shop={shop} />
							<ShoppingCart lang={language} cart={cart} setCart={setCart} setTitle={setTitle} setArticle={setArticle} page={page} setPage={setPage} shop={shop} />
						</div>
					</header>
					: ''
				}
				<main>
					{page === 'intro' ?
						<div className="intro-wrapper">
							<div className="boutique comp">
								<div className="comp-title t4" onTouchStart={handleMouseDownShop} onTouchEnd={handleMouseUp} onMouseDown={handleMouseDownShop} onMouseUp={handleMouseUp}>
									Gourmet Boutique
								</div>
								<div className="cta-intro tac flex-center align-center" onClick={() => setShop('shop') + setPage('list') + setTitle(Constants.productNumb)}>
									<FiShoppingCart className="cta-icon" />
									<br />
									<div className="cta-text t4 large">
										{Constants.shopCTA[language]}
									</div>
								</div>
							</div>
							<div className="bakery comp">
								<div className="comp-title t4" onTouchStart={handleMouseDown} onTouchEnd={handleMouseUp} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
									{Constants.breadOrderLabel[language]}
								</div>
								<Bakery />
							</div>
							<div className="rental comp">
								<div className="comp-title t4">
									{Constants.rentalServiceLabel[language]}
								</div>
								<div className="cta-intro tac flex-center align-center" onClick={() => setShop('rental') + setPage('list') + setTitle(Constants.productNumb)}>
									<MdDirectionsBike className="cta-icon" />
									<br />
									<div className="cta-text t4 large">
										{Constants.rentalServiceCTA[language]}
									</div>
								</div>
							</div>
						</div>
						:
						(shop === 'shop') ? <ProductList lang={language} cart={cart} setCart={setCart} article={article} setArticle={setArticle} title={title} setTitle={setTitle} page={page} setPage={setPage} backend={backend} />
							: (shop === 'bakery') ? <BreadOrder lang={language} cart={cart} setCart={setCart} article={article} setArticle={setArticle} title={title} setTitle={setTitle} page={page} setPage={setPage} backend={backend} />
								: (shop === 'rental') ? <RentalService lang={language} cart={cart} setCart={setCart} article={article} setArticle={setArticle} title={title} setTitle={setTitle} page={page} setPage={setPage} backend={backend} /> : ''
					}
				</main>
				<footer>
					<div className="wrapper flex align-center space-between">
						<Language lang={language} setLang={setLanguage} page={page} />
						{page !== 'intro' && page !== 'bread-list' && page !== 'list' && page !== 'checkout' && page !== 'success' ?
							<button onClick={() => setPage('list') + setTitle(Constants.productNumb)} className="homeIcon"><CgMenuGridR color="white" /></button>
							: ''
						}
						{page !== 'intro' ?
							<button onClick={() => window.location.reload(true)} className="exitButton flex align-center">EXIT<ImExit color="white" /></button>
							: ''
						}
					</div>
				</footer>
			</div>
		);
	}

}

export default App;