import * as Constants from '../constants';
import {IoIosArrowBack} from 'react-icons/io';

function NavHeader(prop) {
	if(prop.title.length !== 0 && prop.page !== 'checkout' && prop.page !== 'success') {
		return (
			<nav className="head-nav flex align-center">
				<button className="back" onClick={() => {
					if(prop.page !== 'bread-list' && prop.page !== 'list') {
						if(prop.title === Constants.cartLabel && prop.article) {
							prop.setTitle(prop.article);
							prop.setPage('detail');
						}
						else if(prop.title === Constants.checkOutLabel || prop.title === Constants.summaryLabel) {
							prop.setTitle(Constants.cartLabel);
							prop.setPage('cart');
						}
						else if(prop.page === 'list' || prop.page === 'bread-list' || prop.page === 'rental-list') {
							window.location.reload(true)
						}
						else {
							prop.setTitle(Constants.productNumb);
							prop.setArticle(false);
							prop.setPage('list');
						}
					}
				}}>
				{prop.page !== 'bread-list' && prop.page !== 'list'
					? <IoIosArrowBack fill="white" />
					: ''
				}
				</button>
				<div className="title t4">
					{prop.page === 'detail' ? prop.article : prop.title[prop.lang]}
				</div>
			</nav>
		)
	}
	else return '';
}

export default NavHeader;