const Language = (prop) => {

	const changeLang = (lang) => {
		prop.setLang(lang);
		document.documentElement.lang = lang;
	}

	return (
		<nav className="lang">
			{prop.lang !== 'de' ? <button className="de" onClick={() => changeLang('de')}>DEUTSCH</button> : ''}
			{prop.lang !== 'it' ? <button className="it" onClick={() =>  changeLang('it')}>ITALIANO</button> : ''}
			{prop.lang !== 'en' ? <button className="en" onClick={() => changeLang('en')}>ENGLISH</button> : ''}
		</nav>
	)

}

export default Language;